import { createRouter, createWebHistory } from 'vue-router'
import Author from '../components/Author.vue'

const routes = [
	{
		path: "/",
		redirect: { path: "/author" }, // redirect property 
	},
	{
		path: '/author',
		name: 'Author',
		component: Author
	},
	{
		path: '/businesscareer/:imageUrl?',
		name: 'BusinessCareer',
		component: () => import('../components/BusinessCareer.vue')
	},
	{
		path: '/cv/:imageUrl?',
		name: 'CV',
		component: () => import('../components/CV.vue')
	},
	{
		path: '/speaker/:imageUrl?',
		name: 'Speaker',
		component: () => import('../components/Speaker.vue')
	},
	{
		path: '/contact/:imageUrl?',
		name: 'Contact',
		component: () => import('../components/Contact.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router