<template>
	<div class="container mx-auto min-h-screen bg-slate-100 text-slate-800">
		<div class="text-2xl md:text-5xl text-center p-1 md:p-4">Wolfgang Grulke</div>

		<div class="md:shrink-0">
			<img class="md:w-1/4  mx-auto rounded" :src="getImageUrl('')" />
		</div>

		<nav class="bg-slate-300 mx-auto  lg:max-w-[50%] sm:max-w-[80%] shadow-lg my-1 md:my-4 rounded">
			<div class="flex justify-center" v-on:click="toggleMenu()">
				<!-- Primary Navbar items -->
				<div class="hidden md:flex items-center space-x-4 text-gray-500 font-semibold">
					<router-link class="py-4 hover:text-sky-500 menuItem-active-link" to="/author">Author and collector</router-link>
					<router-link class="py-4 hover:text-sky-500 menuItem-active-link" :to="{name: 'BusinessCareer', params: {imageUrl: 'WolfgangGrulke-Business.jpg'}}">Business career</router-link>
					<router-link class="py-4 hover:text-sky-500 menuItem-active-link" :to="{name: 'CV', params: {imageUrl: 'WolfgangGrulke-Business.jpg'}}">CV</router-link>
					<router-link class="py-4 hover:text-sky-500 menuItem-active-link" :to="{name: 'Speaker', params: {imageUrl: 'WolfgangGrulke-Business.jpg'}}">Speaker</router-link>
					<router-link class="py-4 hover:text-sky-500 menuItem-active-link" :to="{name: 'Contact', params: {imageUrl: 'WolfgangGrulke-Contact.png'}}">Contact</router-link>
				</div>

				<!-- Mobile menu button -->
				<div class="md:hidden flex items-center">
					<button class="outline-none">
						<svg class="w-6 h-6 text-gray-700"
							 fill="none"
							 stroke-linecap="round"
							 stroke-linejoin="round"
							 stroke-width="2"
							 viewBox="0 0 24 24"
							 stroke="currentColor">
							<path d="M4 6h16M4 12h16M4 18h16"></path>
						</svg>
					</button>
				</div>
			</div>

			<!-- mobile menu -->
			<div class="md:hidden text-sky-500" v-show="IsMenuVisible">
				<router-link class="block p-2 menuItem-active-link" to="/author" v-on:click="toggleMenu()">Author and collector</router-link>
				<router-link class="block p-2 menuItem-active-link" :to="{name: 'BusinessCareer', params: {imageUrl: 'WolfgangGrulke-Business.jpg'}}" v-on:click="toggleMenu()">Business career</router-link>
				<router-link class="block p-2 menuItem-active-link" :to="{name: 'CV', params: {imageUrl: 'WolfgangGrulke-Business.jpg'}}" v-on:click="toggleMenu()">CV</router-link>
				<router-link class="block p-2 menuItem-active-link" :to="{name: 'Speaker', params: {imageUrl: 'WolfgangGrulke-Business.jpg'}}" v-on:click="toggleMenu()">Speaker</router-link>
				<router-link class="block p-2 menuItem-active-link" :to="{name: 'Contact', params: {imageUrl: 'WolfgangGrulke-Contact.png'}}" v-on:click="toggleMenu()">Contact</router-link>
			</div>
		</nav>

		<div class="lg:leading-loose md-flex lg:max-w-[50%] sm:max-w-[80%] tracking-wide mx-auto">
			<router-view />
		</div>
		<br />
		<br />
	</div>

</template>

<script>
	import Contact from './components/Contact.vue'
	import Author from './components/Author.vue'
	import BusinessCareer from './components/BusinessCareer.vue'
	import CV from './components/CV.vue'
	import Speaker from './components/Speaker.vue'

	export default {
		name: 'App',
		components: {
			Contact,
			Author,
			BusinessCareer,
			CV,
			Speaker
		},
		data() {
			return {
				IsMenuVisible: false
			}
		},
		methods: {
			toggleMenu() {
				this.IsMenuVisible = !this.IsMenuVisible;
			},
			getImageUrl: function (imageName) {

				if (this.$route.params.imageUrl != null && this.$route.params.imageUrl != "undefined")
					imageName = this.$route.params.imageUrl;

				
				if (imageName == "") {
					console.log(this.$route.path);

					if (this.$route.path.includes("businesscareer") || this.$route.path.includes("speaker") || this.$route.path.includes("cv"))
						imageName = "WolfgangGrulke-Business.jpg";
					else if (this.$route.path.includes("contact"))
						imageName = "WolfgangGrulke-Contact.png";

					if (imageName == "") 
						imageName = "WolfgangGrulke.jpeg";
				}

				let imageUrl = require.context('./assets/', false, /\.png$|\.jpg|\.jpeg$/);
				return imageUrl("./" + imageName);
			}
		}
	}
</script>

<style>
	.router-link-exact-active.menuItem-active-link {
		border-bottom-color: #F19238;
		color: #FF0000
	}
</style>
