<template>
	<div class="px-2">
		<div class="pb-4">
			<p class="pb-3">
				A former IBM executive, Wolfgang worked internationally with IBM for more than 25 years and was awarded the prestigious <span class="font-semibold italic">IBM Outstanding Innovation Award</span>. He was founder and CEO of the Business Futures Group and started <span class="font-semibold italic">FutureWorld</span> as an informal business network in 1987.
			</p>
			<p class="pb-3">
				Since that time he and his team have been instrumental in helping major corporations and venture capital firms position themselves for the new world economy. He has addressed audiences in more than 20 countries, in person, on radio and on television.
			</p>
			<p class="pb-3">
				His article <span class="font-semibold italic">In Search of Simplicity</span> won the NACCA award for the best business article of the year and <span class="font-semibold italic">From Value Chain to Marketspace</span> was awarded the AFSM International Writing Award for Professional Writers and Consultants in Boston.
			</p>
			<p class="pb-3">
				Wolfgang was a <span class="font-semibold italic">Fellow of the Centre for Management Development at the London Business School</span> and taught regularly on a number of programmes including the School's flagship Senior Executive Programme.
			</p>
		</div>

		<div class="pb-4">
			His philosophy is epitomised by his saying <span class="font-semibold italic">"It is no longer possible to learn just from experience - in this warp-speed world, it has become essential to learn from the future!"</span>
		</div>

		<div class="pb-4">
			He is currently <span class="font-semibold italic">Chairman Emeritus of FutureWorld International</span>. His business books include:
		</div>
		<div class="pb-4 pl-6 font-semibold italic">
			10 Lessons from the Future: Your future is a matter of choice, not chance.
		</div>
		<div class="pb-4 pl-6 font-semibold italic">
			Lessons in Radical Innovation: Out of the box, straight to the bottom line.
		</div>
		<div class="pb-4">
			Both published by Financial Times/Pearson. Also available in Spanish, Chinese and Arabic.
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BusinessCareer',
	}
</script>
