<template>

	<div class="grid grid-cols-2 gap-4 lg:text-xl sm:text-normal">
		<div class="pl-2 md:shrink-0">
			<img class="max-w-1/2 md:max-w-1/8 mx-auto my-2 lg:my-4 rounded" src="../assets/MAFrontCover.png" />
		</div>
		<div class="lg:pt-[35%] sm:pt-[15%] pr-2">
			<a href="https://maryanningreimagined.atone.org" target="_blank" class="text-sky-500 underline"><span class="font-semibold">She Sold Seashells:</span> The curious Mary Anning, re-imagined.</a>
		</div>
	</div>

	<div class="grid grid-cols-2 lg:text-large sm:text-normal">
		<div class="lg:pt-[35%] sm:pt-[15%] pl-2">
			<a href="https://adornedbynature.atone.org" target="_blank" class="text-sky-500 underline"><span class="font-semibold">Adorned by Nature:</span></a> Adornment, exchange & myth in the South Seas
		</div>
		<div class="pr-2 border-solid md:shrink-0">
			<img class="max-w-1 mx-auto my-2 lg:my-4" src="../assets/bookAdornedByNature.png" />
		</div>
	</div>

	<div class="px-2">
		<div class="font-semibold text-xl">THE DEEP TIME TRILOGY</div>

		<div class="lg:max-w-[100%] sm:max-w-[50%] content-center">
			<img class="max-w-full md:shrink-0 mx-auto" src="../assets/DeepTimeTrilogy.png" />
		</div>

		<div>
			<a href="https://heteromorph.atone.org" target="_blank" class="text-sky-500 underline"><span class="font-semibold">Heteromorph:</span> The rarest ammonite fossils</a> - Nature at its most bizarre
		</div>

		<p>
			<a href="https://nautilus.atone.org" target="_blank" class="text-sky-500 underline"><span class="font-semibold">Nautilus:</span> Beautiful survivor</a> - 500 million years of evolutionary history
		</p>

		<p>
			<a href="https://beyondextinction.atone.org" target="_blank" class="text-sky-500 underline"><span class="font-semibold">Beyond Extinction:</span> The Eternal Ocean</a> - Climate Change and the Continuity of Life
		</p>

		<div class="grid grid-cols-2 pt-2 lg:text-large sm:text-normal">
			<div class="lg:pt-[35%] sm:pt-[15%] pl-2">
				<span class="font-semibold">Aldabra: World Heritage Site</span> - Wolfgang contributed the chapter on the marine biology of Aldabra
			</div>
			<div class="pr-2 border-solid md:shrink-0">
				<img class="max-w-full mx-auto my-2 lg:my-4" src="../assets/Aldabra.jpg" />
			</div>
		</div>

		<p class="pt-8 text-base uppercase">
			An inveterate collector, wolfgang collects ethnic adornments made from natural materials from the islands of oceania and has established what is now regarded as one of the world's premier fossil collections:
		</p>
		<p class="py-4">
			<span class="italic text-xl">One of the most remarkable collections of fossils ever assembled.</span><br />
			<span class="text-lime-500 font-semibold">Ted Nield</span> - Editor, GeoScientist,Journal of the Geological Society, London
		</p>

		<p class="pb-4">
			<span class="italic text-xl">
				I've seen many museums and collections, but this is the most remarkable and exciting ever. I feel privileged and inspired.
			</span><br />
			<span class="text-pink-700 font-semibold">Philip Powell</span> - Curator of the Geological Collections,Oxford University Museum of Natural History
		</p>
		<p class="pb-4">
			<span class="italic text-xl">
				This is my boyhood dream. I never believed so many wonders could be gathered under one roof.
			</span><br />
			<span class="text-cyan-800 font-semibold">The late Michael Hanlon</span> - CEO, Jurassica
		</p>
		<p class="pb-4">
			<span class="italic text-xl">
				I see a good number of private collections, but I can safely say that yours is unique.
			</span><br />
			<span class="text-amber-500 font-semibold">Paul Callomon</span> - Collection Manager, Academy of Natural Sciences of Drexel University, Philadelphia
		</p>
		<p class="pb-4">
			<span class="italic text-xl">
				I am - truly - lost for words.
			</span><br />
			<span class="text-indigo-600 font-semibold">Sir David Attenborough</span> - Broadcaster and natural historian
		</p>
		<p class="pb-4">
			<span class="italic text-xl">
				A gentleman's collection of natural wonders. An amazing display unrivalled by any museum or private collection in the world.
			</span><br />
			<span class="text-red-600 font-semibold">Tom Kapitany</span> - Director, National Dinosaur Museum, Australia
		</p>
		<p class="pb-4">
			<span class="italic text-xl">
				An underwater photographer of rare talent.
			</span><br />
			<span class="text-teal-500 font-semibold">Africa Geographic</span>
		</p>
	</div>
</template>

<script>
	export default {
		name: 'Author',
	}
</script>
