<template>
	<div class="px-2">
		<p class="pb-3">
			Wolfgang has spoken at conferences, dinners and corporate events in more than 20 countries around the world over a period 30 years, to audiences of 10 to 10 000 people.
		</p>
		<p class="pb-5">
			A common thread in his talks has been his mantra...
			<br />
			<span class="font-semibold italic">
				"In this warp-speed world it is no longer enough to learn from experience - it is essential to learn from the future."
			</span>
		</p>

		<p class="pb-3">
			Here are some client references...
		</p>

		<div class="leading-normal">
			<p class="font-semibold italic text-lg">
				"Practical and inspirational. Highly relevant if you don't want someone else eating your cheese".
			</p>
			<p class="pb-4 text-sm">
				Wall Street Journal.com
			</p>

			<p class="font-semibold italic text-lg">
				"The cutting edge of what's coming in the future".
			</p>
			<p class="pb-4 text-sm">
				Kate Bulkley, media journalist
			</p>

			<p class="font-semibold italic text-lg">
				"Wolfgang is one of the few thinkers able to shake up the intellectual trajectories of the world's leading executives.  He expands the range of thought by challenging you to imagine and consider the possible.  His insights are a 'must' for those responsible for creating vision."
			</p>
			<p class="pb-4 text-sm">
				JoEllyn Prouty McLaren: Director, Executive Education, London Business School
			</p>

			<p class="font-semibold italic text-lg">
				"I haven't seen the challenges and opportunities of future business, communities and people so powerfully presented before. Wolfgang's life cycles reminded me exactly of what went right and wrong in our business."
			</p>
			<p class="pb-4 text-sm">
				Dame Anita Roddick, Founder of The Body Shop
			</p>

			<p class="font-semibold italic text-lg">
				"Wolfgang's scenarios on the future of business and technology inspired me to change our traditional business focus and develop our global internet strategy. Our success has been dramatic since then, culminating in recently being awarded the largest hospitality contract in the world, supplying more than 4,500 major hotels in the USA, Canada, Mexico and the Caribbean with our 'Chef Works' hospitality clothing brand."
			</p>
			<p class="pb-4 text-sm">
				Alan J. Gross - CEO Gross & Co www.chefworks.com
			</p>

			<p class="font-semibold italic text-lg">
				"One of the best speakers I have ever heard - gave one of the most thought-provoking and exciting presentations I have ever experienced."
			</p>
			<p class="pb-4 text-sm">
				Ajay Choudhury, IDG Ventures Europe at GSM Congress at Cannes
			</p>

			<p class="font-semibold italic text-lg">
				"Wolfgang's thinking is constantly challenging the norms and dares you to go out of the box. Supercharged by his passion, this is a unique and powerful skill."
			</p>
			<p class="pb-4 text-sm">
				Martin George, Commercial Director, British Airways
			</p>

			<p class="font-semibold italic text-lg">
				"Wolfgang has given some of the most memorable and highly-rated presentations in YPO history."
			</p>
			<p class="pb-4 text-sm">
				Young Presidents' Organization - USA, Asia and Europe
			</p>

			<p class="font-semibold italic text-lg">
				"Grulke's thinking is original and frame-breaking."
			</p>
			<p class="pb-4 text-sm">
				John Stopford, Professor of International Business, London Business School
			</p>

			<p class="font-semibold italic text-lg">
				"Wow! That was great! I loved listening to your ideas!  That was the most important set of concepts that I've heard anyone talk about in a long time."
			</p>
			<p class="pb-4 text-sm">
				Greg Homan, Director, Emerging Technologies, Nextel Communications
			</p>

			<p class="font-semibold italic text-lg">
				"Close your eyes, listen to Wolfgang, and you will be reminded of the story-telling genius of Robin Williams."
			</p>
			<p class="pb-4 text-sm">
				Charles Stilley, President, AMC Theatres
			</p>

			<p class="font-semibold italic text-lg">
				"One of the most insightful presentations I've ever heard".
			</p>
			<p class="pb-4 text-sm">
				Javier Roza, Managing Director, Unilever Portugal
			</p>

			<p class="font-semibold italic text-lg">
				"This was the best, most provocative summary ever about (the future of) China".
			</p>
			<p class="pb-4 text-sm">
				William Kistler, President, Urban Land Institute, Europe
			</p>
			<p class="font-semibold italic text-lg">
				"In previous consulting relationships there has always been an obsession with driving costs down.  Our partnership with FutureWorld goes way beyond that, to a constant drive towards new ideas and new revenue opportunities. It's amazing how much we have learnt from the future. That's unique in my experience, and great fun."
			</p>
			<p class="pb-4 text-sm">
				Bernard Swanepoel, CEO Harmony Gold Limited
			</p>

			<p class="font-semibold italic text-lg">
				"A mind-blowing presentation on change and the future of financial services."
			</p>
			<p class="pb-4 text-sm">
				Sohana Maharaj, Chief Operating Officer, ABSA Brokers
			</p>

			<p class="font-semibold italic text-lg">
				"Fantastic and insightful; extremely relevant in our context."
			</p>
			<p class="pb-4 text-sm">
				Peter Sands, Group CEO, Standard Chartered Bank
			</p>

			<p class="font-semibold italic text-lg">
				"Your workshop at the London Business School last year was a milestone in my life. I would like to thank you for that. I have just visited New York with my family and we saw some of the innovations you spoke about happening. It was a great experience, and now they can be convinced that what I told them after your session was true!"
			</p>
			<p class="pb-4 text-sm">
				Jaron Wiedmaier, General Director, Continental Tires
			</p>

			<p class="font-semibold italic text-lg">
				"This was the most powerful presentation I have ever seen. You have absolutely shaken us out of complacency. We must 'think future' everyday, not just at annual retreats. There is no yesterday, only tomorrow."
			</p>
			<p class="pb-4 text-sm">
				Kitty Choi , Head, Efficiency Unit, Hong Kong Government
			</p>

			<p class="font-semibold italic text-lg">
				"Wolfgang was so inspirational that you could not but be excited! He was saying it is great to think big and be emotionally excited by taking on challenging opportunities. It was against this backdrop that the acquisition of Laing took place. We looked at the risks - and the opportunities. Although incremental improvements are OK, you do need a step change."
			</p>
			<p class="pb-4 text-sm">
				Ray O'Rourke, CEO Laing O'Rourke
				<br/>
				Financial Times, 14 May 2007
			</p>

			<p class="font-semibold italic text-lg">
				"You have given us a great deal of food for thought about the future."
			</p>
			<p class="pb-4 text-sm">
				Victor Fung, Group Chairman, The li & Fung Group, Hong Kong. Inscription in the book 100 Years of Li & Fung
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Speaker',
	}
</script>