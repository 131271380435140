<template>
	<div class="grid place-items-center">
		<p>
			<span class="font-semibold">Email:</span> Wolfgang.Grulke@gmail.com
		</p>
		<p>
			<span class="font-semibold">Mobile:</span> +44 7917 152 115
		</p>
		<p>
			<span class="font-semibold">Skype:</span> wolfgang.grulke
		</p>
		<p>
			<span class="font-semibold">Twitter:</span> @WolfgangGrulke
		</p>
		<p>
			<span class="font-semibold">Instagram:</span> WolfgangEGrulke
		</p>
		<p>
			<span class="font-semibold">Facebook:</span> www.facebook.com/WolfgangEGrulke
		</p>
	</div>
</template>

<script>
	export default {
		name: 'Contact',
	}
</script>
