<template>
	<div class="px-2">
		<div class="pb-4 leading-normal">
			<p class="pb-4">
				Wolfgang Grulke is an entrepreneur and an award-winning author on the natural world, the future of business and technology.
			</p>
			<p class="pb-4">
				After a long career with IBM world-wide, he founded the FutureWorld think tank and authored the prescient 2000 book <span class="text-sky-500">10 Lessons from the Future</span> which anticipated the profound impact of the internet and mobile technologies. As a Fellow of the Centre for Management Development at the London Business School he taught on the strategic impact of technology at several international business schools, including the LBS' flagship Senior Executive Programme. He also served on Deloitte's Global Innovation Board and created a FutureWorld/Deloitte innovation joint-venture, of which some results were contained in his book <span class="text-sky-500">Radical Innovation - Out of the box straight to the bottom line</span>.
			</p>
			<p class="pb-4">
				His travels have covered the globe and he has spoken to audiences of up to 10 000 senior executives at a time, in more than 25 countries. The Young President's Organization voted him one of their top speakers of all time.
			</p>
			<p class="pb-4">
				In parallel, with his passion for nature and an all-embracing sense of curiosity and wonder, he has explored and photographed ocean life and established what is regarded as one of the world's premier fossil and natural history collections. Sir David Attenborough was "truly lost for words" on viewing this cabinet of curiosity.
			</p>
			<p class="pb-4">
				Wolfgang's <a href="https://beyondextinction.atone.org/" target="_blank" class="text-sky-500">Deep Time Trilogy</a> was voted the best non-fiction book series in 2020. His 2022 book <a href="https://adornedbynature.atone.org" target="_blank" class="text-sky-500">Adorned by Nature</a> celebrates the magnificent adornment cultures of the peoples of the South Pacific.
			</p>
			<p class="pb-4">
				Recently, he combined his love for technology, AI and palaeontology to create a book on the young Mary Anning, titled <a href="https://maryanningreimagined.atone.org/" target="_blank" class="text-sky-500">She sold seashells ...and dragons</a>, in association with the Lyme Regis Museum, that re-imagines her young life with the first images of her as a teenager and in her twenties, when she made many of her important discoveries.
			</p>
			<p class="pb-4">
				Wolfgang is a Fellow of the Royal Geographical Society and lives in the Dorset countryside with his wife, Teresa Martin, and their two dogs.
			</p>

		</div>


	</div>
</template>

<script>
	export default {
		name: 'CV',
	}
</script>
